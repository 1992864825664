<template>
  <section>
    <section class="top">
      <div>
        <p class="st_title">
          Settings
        </p>
        <p class="st_subtitle">
          List Of Settings
        </p>
      </div>
      <div class="top-btn">
        <VBtn
          round
          large
          class="shadow"
          color="white"
          @click="confSaveAll = true"
        >
          Save Changes
        </VBtn>
      </div>
    </section>
    <div>
      <VDialog
        v-model="confSaveAll"
        width="300"
        persistent
      >
        <ConfirmAction
          @confirm="saveAll()"
          @close="confSaveAll = false"
        />
      </VDialog>
    </div>
    <section>
      <div class="content">
        <div class="grid_simple">
          <div>
            <div class="grid_options">
              <VCard class="st_card_defaults shadow">
                <div>
                  <p class="st_main_title">
                    Class
                  </p>
                  <p class="st_main_subtitle">
                    Add Or Remove Classes
                  </p>
                </div>
                <div>
                  <VTextField
                    v-model="stdClass"
                    class="mt-3"
                    solo
                    flat
                    background-color="background"
                    :hide-details="true"
                    placeholder="Ex: 5 / 5A"
                    append-icon="add"
                    :append-outer-icon="expandClass ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                    @click:append="addClass(stdClass)"
                    @click:append-outer="expandClass = !expandClass"
                    @keyup.enter="addClass(stdClass)"
                  />
                </div>
                <VSlideYTransition>
                  <div
                    v-show="expandClass"
                    class="grid_chip"
                  >
                    <div
                      v-for="(cl, index) in stdClassList"
                      :key="index"
                    >
                      <VChip
                        close
                        color="chip"
                        dark
                        @input="remClass(index)"
                      >
                        <span>{{ cl }}</span>
                      </VChip>
                    </div>
                  </div>
                </VSlideYTransition>
              </VCard>
              <VCard class="st_card_defaults shadow">
                <div>
                  <p class="st_main_title">
                    Subject
                  </p>
                  <p class="st_main_subtitle">
                    Add Or Remove Subjects
                  </p>
                </div>
                <div>
                  <VTextField
                    v-model="subject"
                    class="mt-3"
                    solo
                    flat
                    background-color="background"
                    :hide-details="true"
                    placeholder="Ex: English"
                    append-icon="add"
                    :append-outer-icon="expandSub ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                    @click:append="addSub(subject)"
                    @click:append-outer="expandSub = !expandSub"
                    @keyup.enter="addSub(subject)"
                  />
                </div>
                <VSlideYTransition>
                  <div
                    v-show="expandSub"
                    class="grid_chip_sub"
                  >
                    <div
                      v-for="(sub, index) in subjectList"
                      :key="index"
                    >
                      <VChip
                        close
                        color="chip"
                        dark
                        @input="remSub(index)"
                      >
                        <span>{{ sub }}</span>
                      </VChip>
                    </div>
                  </div>
                </VSlideYTransition>
              </VCard>
              <VCard class="st_card_defaults shadow">
                <div>
                  <p class="st_main_title">
                    Term
                  </p>
                  <p class="st_main_subtitle">
                    Add Or Remove Terms
                  </p>
                </div>
                <div>
                  <VTextField
                    v-model="term"
                    class="mt-3"
                    solo
                    flat
                    background-color="background"
                    :hide-details="true"
                    placeholder="Ex: SA1"
                    append-icon="add"
                    :append-outer-icon="expandTerm ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                    @click:append="addTerm(term)"
                    @click:append-outer="expandTerm = !expandTerm"
                    @keyup.enter="addTerm(term)"
                  />
                </div>
                <VSlideYTransition>
                  <div
                    v-show="expandTerm"
                    class="grid_chip"
                  >
                    <div
                      v-for="(terms, index) in termsList"
                      :key="index"
                    >
                      <VChip
                        close
                        color="chip"
                        dark
                        @input="remTerm(index)"
                      >
                        <span>{{ terms }}</span>
                      </VChip>
                    </div>
                  </div>
                </VSlideYTransition>
              </VCard>
            </div>
          </div>
          <div>
            <VCard class="st_card_defaults shadow">
              <div class="ml-2 mb-4 addUser">
                <div>
                  <p class="st_main_title">
                    User
                  </p>
                  <p class="st_main_subtitle">
                    Add Or Remove User
                  </p>
                </div>
                <div class="mr-2">
                  <VBtn
                    depressed
                    round
                    color="background"
                    @click="openUserSetting = true"
                  >
                    Add User
                  </VBtn>
                </div>
              </div>
              <div class="grid_user">
                <VSheet
                  v-for="user in userList"
                  :key="user.id"
                >
                  <VListTile>
                    <VListTileAvatar color="primary">
                      <span class="white--text">{{ user.data.name.charAt(0).toUpperCase() }}</span>
                    </VListTileAvatar>
                    <VListTileContent>
                      <VListTileTitle class="st_main_txt">
                        {{ user.data.name }}
                      </VListTileTitle>
                      <VListTileSubTitle class="st_main_subtxt text-capitalize">
                        {{ user.data.role }}
                      </VListTileSubTitle>
                    </VListTileContent>
                    <VListTileAction>
                      <VBtn
                        icon
                        @click="userSettings(user.data.uuid)"
                      >
                        <VIcon>settings</VIcon>
                      </VBtn>
                    </VListTileAction>
                  </VListTile>
                </VSheet>
              </div>
            </VCard>
          </div>
        </div>
      </div>
    </section>
    <div>
      <VDialog
        v-model="openUserSetting"
        width="300"
        persistent
        scrollable
      >
        <VCard class="st_card_defaults h100">
          <div>
            <p class="st_main_title">
              User Settings
            </p>
            <p class="st_main_subtitle">
              Add User
            </p>
          </div>
          <VForm
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <div>
              <VTextField
                v-model="userName"
                :rules="[rules.required]"
                solo
                flat
                background-color="background"
                class="mt-4"
                placeholder="Full Name"
                :hide-details="true"
              />
              <VTextField
                v-model="userEmail"
                :rules="[rules.required, rules.email]"
                solo
                flat
                background-color="background"
                class="mt-2"
                placeholder="Email"
                :hide-details="true"
              />
              <div class="mt-4">
                <p class="st_main_subtitle">
                  6 Characters Minimum
                </p>
              </div>
              <VTextField
                v-model="userPass"
                :rules="[rules.required, rules.min]"
                solo
                flat
                background-color="background"
                class="mt-2"
                placeholder="Password"
                :type="show1 ? 'text' : 'password'"
                :hide-details="true"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                @click:append="show1 = !show1"
              />
              <VTextField
                v-model="userPass2"
                :rules="[rules.required, rules.min]"
                solo
                flat
                background-color="background"
                class="mt-2"
                placeholder="Re Enter"
                :type="show2 ? 'text' : 'password'"
                :hide-details="true"
                :append-icon="show2 ? 'visibility' : 'visibility_off'"
                @click:append="show2 = !show2"
              />
            </div>
            <div class="mt-4">
              <p class="st_main_subtitle">
                Assign User Role
              </p>
              <VSelect
                v-model="userRole"
                :rules="[rules.required]"
                class="mt-3 mb-3"
                :items="setRoles"
                item-text="text"
                item-value="value"
                label="Select Role"
                solo
                flat
                background-color="background"
                :hide-details="true"
              />
            </div>
          </VForm>
          <VCardActions class="pa-0">
            <VSpacer />
            <VBtn
              flat
              round
              color="primary"
              @click="openUserSetting = false"
            >
              Close
            </VBtn>
            <VBtn
              flat
              round
              color="primary"
              @click="addUser()"
            >
              Save
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </div>
    <div>
      <VDialog
        v-model="isLoading"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
      >
        <VCard
          v-if="loadStatus === 'loading'"
          class="notification_fs elevation-20"
        >
          <p>Loading</p>
          <span>Fetching Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="white"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_fs elevation-20"
        >
          <p>Internet Issue</p>
          <span>Please Try Again Later</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_fs elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </div>
    <div>
      <VDialog
        v-model="isUpdating"
        width="300"
        persistent
      >
        <VCard
          v-if="loadStatus === 'updating'"
          class="notification_card elevation-20"
        >
          <p>Processing</p>
          <span>Updating Settings, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'updated'"
          class="notification_card elevation-20"
        >
          <p>Updated</p>
          <span>Settings Updated Successfully</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_card elevation-20"
        >
          <p>Internet Issue</p>
          <span>Retrying In {{ retry }} Seconds</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_card elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </div>
    <div>
      <VDialog
        v-model="userOption"
        width="300"
        persistent
        scrollable
      >
        <VCard class="st_card_defaults h100">
          <div>
            <p class="st_main_title">
              User Settings
            </p>
            <p class="st_main_subtitle">
              Update User
            </p>
          </div>
          <div>
            <VTextField
              v-model="userInfo.name"
              solo
              flat
              background-color="background"
              class="mt-4"
              placeholder="Full Name"
              :hide-details="true"
            />
            <VTextField
              v-model="userInfo.email"
              :rules="[rules.required, rules.email]"
              solo
              flat
              background-color="background"
              class="mt-2"
              placeholder="Email"
              :hide-details="true"
            />
            <div class="mt-4">
              <p class="st_main_subtitle">
                6 Characters Minimum
              </p>
            </div>
            <VTextField
              v-model="userPass"
              :rules="[rules.required, rules.min]"
              solo
              flat
              background-color="background"
              class="mt-2"
              placeholder="Password"
              :hide-details="true"
              :type="show3 ? 'text' : 'password'"
              :append-icon="show3 ? 'visibility' : 'visibility_off'"
              @click:append="show3 = !show3"
            />
            <VTextField
              v-model="userPass2"
              :rules="[rules.required, rules.min]"
              solo
              flat
              background-color="background"
              class="mt-2"
              placeholder="Re Enter"
              :hide-details="true"
              :type="show4 ? 'text' : 'password'"
              :append-icon="show4 ? 'visibility' : 'visibility_off'"
              @click:append="show4 = !show4"
            />
          </div>
          <div class="mt-3">
            <VBtn
              block
              depressed
              class="ma-0"
              color="red"
              dark
              @click="userTerminateOption(userInfo.uuid)"
            >
              Terminate Account
            </VBtn>
          </div>
          <div class="mt-4">
            <p class="st_main_subtitle">
              Assign User Role
            </p>
            <VSelect
              v-model="userInfo.role"
              :rules="[rules.required]"
              class="mt-3 mb-3"
              :items="setRoles"
              item-text="text"
              item-value="value"
              label="Select Role"
              solo
              flat
              background-color="background"
              :hide-details="true"
            />
          </div>
          <VCardActions class="pa-0">
            <VSpacer />
            <VBtn
              flat
              round
              color="primary"
              @click="userOption = false"
            >
              Close
            </VBtn>
            <VBtn
              flat
              round
              color="primary"
              @click="userUpdate()"
            >
              Update
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </div>
    <div>
      <VDialog
        v-model="confSave"
        width="300"
        persistent
      >
        <ConfirmAction
          @confirm="userTerminate()"
          @close="confSave = false"
        />
      </VDialog>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../conf/api';
import ConfirmAction from '../components/confirmAction.vue';

export default {
  components: {
    ConfirmAction,
  },
  data: () => ({
    // Required Stuff
    openUserSetting: false,
    valid: true,
    isLoading: true,
    isUpdating: false,
    loadStatus: '',
    retry: 0,
    confSave: false,
    confSaveAll: false,
    // Main Stuff
    allSettings: '',
    stdClass: '',
    stdClassList: [],
    subject: '',
    subjectList: [],
    term: '',
    termsList: [],
    // Expand Options
    expandClass: false,
    expandSub: false,
    expandTerm: false,
    // All User Settings Option
    userName: '',
    userEmail: '',
    userPass: '',
    userPass2: '',
    userRole: '',
    setRoles: [
      { text: 'Teacher', value: 'teacher' },
      { text: 'Manager', value: 'manager' },
      { text: 'Admin', value: 'admin' },
    ],
    userList: [],
    userOption: false,
    userInfo: {
      uuid: '',
      name: '',
      email: '',
      role: '',
    },
    // Extra Options
    userSelectTerminate: '',
    show1: false,
    show2: false,
    show3: false,
    show4: false,
    // Rules For Form Validation
    rules: {
      min: v => v.length >= 6 || 'Min 6 characters',
      email:
        (val) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(val) || 'Invalid e-mail.';
        },
      required: (val) => {
        if (val == null) {
          return 'Required';
        }
        if (val === '') {
          return 'Required';
        }
        return true;
      },
    },
  }),
  computed: {
    userPassCheck() {
      if (this.userPass === this.userPass2) {
        return this.userPass;
      }
      return null;
    },
  },

  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      await axios.get(`${api}/settings/`)
        .then((res) => {
          this.stdClassList = res.data.classes.data;
          this.subjectList = res.data.subjects.data;
          this.termsList = res.data.terms.data;
        });
      await axios.get(`${api}/user/all`)
        .then((res) => {
          if (res.data.status === 'success') {
            this.userList = res.data.data;
          }
          this.isLoading = false;
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    userSettings(uuid) {
      this.userOption = true;
      this.userList.forEach((user) => {
        if (user.data.uuid === uuid) {
          this.userInfo.name = user.data.name;
          this.userInfo.email = user.data.email;
          this.userInfo.role = user.data.role;
          this.userInfo.uuid = user.data.uuid;
        }
      });
    },
    userTerminateOption(val) {
      this.confSave = true;
      this.userSelectTerminate = val;
    },
    userTerminate() {
      this.loadStatus = 'updating';
      this.isUpdating = true;
      axios.post(`${api}/user/terminate`, { userUUID: this.userSelectTerminate }).then(() => {
        this.loadStatus = 'updated';
        axios.get(`${api}/user/all`)
          .then((res2) => {
            if (res2.data.status === 'success') {
              this.userList = res2.data.data;
              this.isUpdating = false;
              setTimeout(() => {
                this.confSave = false;
              }, 150);
              setTimeout(() => {
                this.userOption = false;
              }, 300);
            }
          });
      })
        .catch((e) => {
          if (e.code === 'auth/network-request-failed') {
            this.loadStatus = 'netfail';
            this.retry = 10;
            setInterval(() => {
              this.retry -= 1;
            }, 1000);
            setTimeout(() => {
              window.location.reload();
            }, 9500);
          } else {
            this.loadStatus = 'fail';
          }
        });
    },
    userUpdate() {
      this.loadStatus = 'updating';
      this.isUpdating = true;
      axios.post(`${api}/user/update`, {
        userName: this.userInfo.name,
        userEmail: this.userInfo.email,
        userRole: this.userInfo.role,
        userPass: this.userPassCheck,
        userUUID: this.userInfo.uuid,
      }).then(() => {
        axios.get(`${api}/user/all`)
          .then((res2) => {
            if (res2.data.status === 'success') {
              this.userList = res2.data.data;
              this.isUpdating = false;
              setTimeout(() => {
                this.confSave = false;
              }, 150);
              setTimeout(() => {
                this.userOption = false;
              }, 300);
            }
          });
        this.isUpdating = false;
        setTimeout(() => {
          this.userOption = false;
        }, 300);
      })
        .catch((e) => {
          if (e.code === 'auth/network-request-failed') {
            this.loadStatus = 'netfail';
            this.retry = 10;
            setInterval(() => {
              this.retry -= 1;
            }, 1000);
            setTimeout(() => {
              window.location.reload();
            }, 9500);
          } else {
            this.loadStatus = 'fail';
          }
        });
    },
    async addUser() {
      const schema = {
        userName: this.userName,
        userEmail: this.userEmail,
        userPass: this.userPassCheck,
        userRole: this.userRole,
      };
      if (this.$refs.form.validate() && schema.userPass !== null) {
        this.loadStatus = 'updating';
        this.isUpdating = true;
        await axios.post(`${api}/user/add`, schema).then((res) => {
          if (res.data.status === 'success') {
            axios.get(`${api}/user/all`)
              .then((res2) => {
                if (res2.data.status === 'success') {
                  this.loadStatus = 'updated';
                  this.userList = res2.data.data;
                  setTimeout(() => {
                    this.isUpdating = false;
                  }, 300);
                  setTimeout(() => {
                    this.openUserSetting = false;
                    this.userName = '';
                    this.userEmail = '';
                    this.userPass = '';
                    this.userPass2 = '';
                    this.userRole = '';
                  }, 500);
                }
              });
          } if (res.data.status === 'error') {
            this.loadStatus = 'fail';
            setTimeout(() => {
              this.isUpdating = false;
            }, 1000);
          }
        })
          .catch((e) => {
            if (e.code === 'auth/network-request-failed') {
              this.loadStatus = 'netfail';
              this.retry = 10;
              setInterval(() => {
                this.retry -= 1;
              }, 1000);
              setTimeout(() => {
                window.location.reload();
              }, 9500);
            } else {
              this.loadStatus = 'fail';
            }
          });
      }
    },
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    async saveAll() {
      try {
        this.confSaveAll = false;
        this.loadStatus = 'updating';
        this.isUpdating = true;
        await axios.post(`${api}/settings/classes/add`,
          this.stdClassList);
        await axios.post(`${api}/settings/subjects/add`,
          this.subjectList);
        await axios.post(`${api}/settings/terms/add`,
          this.termsList)
          .then(() => {
            this.loadStatus = 'updated';
            setTimeout(() => {
              this.isUpdating = false;
            }, 2500);
          });
      } catch (e) {
        if (e.code === 'auth/network-request-failed') {
          this.loadStatus = 'netfail';
          this.retry = 10;
          setInterval(() => {
            this.retry -= 1;
          }, 1000);
          setTimeout(() => {
            window.location.reload();
          }, 9500);
        } else {
          this.loadStatus = 'fail';
        }
      }
    },
    // Teacher
    // addTeach(teach) {
    //   const teachInput = teach.trim();
    //   if (teachInput.length >= 1) {
    //     this.teacherList.unshift(teachInput);
    //   }
    //   this.teacher = '';
    // },
    // remTeach(teach) {
    //   this.teacherList.splice(teach, 1);
    // },
    // Class
    addClass(cl) {
      this.expandClass = true;
      const classInput = cl.trim();
      if (classInput.length >= 1) {
        this.stdClassList.unshift(classInput);
      }
      this.stdClass = '';
    },
    remClass(cl) {
      this.stdClassList.splice(cl, 1);
    },
    // Subject
    addSub(sub) {
      this.expandSub = true;
      const subInput = sub.trim();
      if (subInput.length >= 1) {
        this.subjectList.unshift(subInput);
      }
      this.subject = '';
    },
    remSub(sub) {
      this.subjectList.splice(sub, 1);
    },
    // Term
    addTerm(sub) {
      this.expandTerm = true;
      const subInput = sub.trim();
      if (subInput.length >= 1) {
        this.termsList.unshift(subInput);
      }
      this.term = '';
    },
    remTerm(sub) {
      this.termsList.splice(sub, 1);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/settings';
</style>
